<template>

<!-- 用户管理 机构管理页面 编辑-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div  class="organization-container">
              <div class="organization-header all-section-header">
                <p class="header-title">
                  机构信息
                </p>
              </div>
              <div class="organization-from_section">
                <el-form label-position="right" label-width="120px" :model="basicInfo" :rules="basicInfoRules">
                  <el-form-item label="机构名称" prop="name">
                    <el-input v-model="basicInfo.name"></el-input>
                  </el-form-item>
                  <el-form-item label="维度">
                    <el-input v-model="basicInfo.longitude"></el-input>
                  </el-form-item>
                  <el-form-item label="经度">
                    <el-input v-model="basicInfo.latitude"></el-input>
                  </el-form-item>
                  <el-form-item label="地址">
                    <el-input v-model="basicInfo.location"></el-input>
                  </el-form-item>
                  <el-form-item label="电话" prop="phone">
                    <el-input maxlength="11" v-model="basicInfo.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="所在省">
                    <el-input v-model="basicInfo.provinceCode"></el-input>
                  </el-form-item>
                  <el-form-item label="所在市">
                    <el-input v-model="basicInfo.cityCode"></el-input>
                  </el-form-item>
                  <el-form-item label="官方网址">
                    <el-input v-model="basicInfo.officialWebsite"></el-input>
                  </el-form-item>
                  <el-form-item label="简介" prop="intro">
                    <el-input v-model="basicInfo.intro"></el-input>
                  </el-form-item>
                  <el-form-item label="营业执照" prop="businessLicense">
                    <el-upload
                      class="upload-demo"
                      drag
                      action="https://jsonplaceholder.typicode.com/posts/"
                      multiple>
                      <i class="el-icon-camera"></i>
                      <div class="el-upload__text">请上传不大于2M的jpg/png格式的图</div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="医疗许可证" prop="medicalLicense">
                    <el-upload
                      class="upload-demo"
                      drag
                      action="https://jsonplaceholder.typicode.com/posts/"
                      multiple>
                      <i class="el-icon-camera"></i>
                      <div class="el-upload__text">请上传不大于2M的jpg/png格式的图</div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="法人身份证号" prop="legalPersonCard">
                    <el-input v-model="basicInfo.intro"></el-input>
                  </el-form-item>
                </el-form>
                <div class="button-section">
                  <el-button class="add-button">提交</el-button>
                  <el-button class="return-button">取消</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      basicInfo: {
        name: '',
        longitude: '',
        latitude: '',
        location: '',
        phone: '',
        provinceCode: '',
        cityCode: '',
        officialWebsite: '',
        intro: '',
        detailImg: '',
        businessLicense: '',
        medicalLicense: ''
      },
      basicInfoRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' }
        ],
        businessLicense: [
          { required: true, message: '请选择营业执照', trigger: 'change' }
        ],
        medicalLicense: [
          { required: true, message: '请选择医疗许可证', trigger: 'change' }
        ],
        legalPersonCard: [
          { required: true, message: '请输入法人身份证号', trigger: 'change' }
        ],
        intro: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // this.getData()
  },
  methods: {
    getData () {
      AdministratorService.AdministratorUpdateHospital({ id: this.$route.params.id }).then(res => [
        console.log(res)
      ])
    },
    saveImage (flie) {
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;
  .body-container{
    padding: 20px 40px 20px 61px;
    .body-section{
      min-width: 450px;
    }
    .basic-container{
      max-width: 450px;
      .el-select{
        width: 100%;
      }
    }
    .all-container{
      .all-section-header{
          width: 100%;
          min-width: 160px;
          height: 50px;
          background-color: #F8F8F8;
          padding:  0 30px;
          border-radius: 6px;
          margin-bottom: 40px;
        .header-title{
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }
      .organization-from_section{
        max-width: 450px;
        /deep/.el-icon-camera{
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }
        /deep/.el-upload-dragger{
          width: 350px;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .government-from_section{
        max-width: 450px;
        .el-select{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .school-from_section{
        max-width: 450px;
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .class-from_section{
        max-width: 450px;
        .el-select, .el-autocomplete{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}
/deep/.el-popper, .el-select-dropdown {
  .hover{
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
/* eslint-disable */
